import React, { useCallback, useContext, useEffect, useState } from "react";
import { SIDEBAR_DATA } from "../../utils/sidebar";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { routes } from "../../routes/routes";
import Logout_sign from "../../assets/images/logout_sign.png";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../../firebase_config";
import { checkPermission } from "../../auth/checkPermission";
import { ModalContext } from "../../context/ModalProvider";
import common_service from "../../api/common_service";

export default function Sidebar({ activePageIndex, setDrawerSidebar }) {
  // this is for token expire popup
  const { showModal } = useContext(ModalContext);
  common_service.init(showModal);
  const companyData = useSelector((state) => state.companySlice.companyData);
  const location = useLocation();
  const dispatch = useDispatch();
  const [expandedDrawer, setExpandedDrawer] = useState({
    status: false,
    fullTextActive: false,
  });
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSubIndex, setActiveSubIndex] = useState(0);
  const [drawer, setDrawer] = useState({ index: null });

  const handleDrawer = (index) => {
    setDrawer({ index: index });
  };

  const handleSidebarExpansion = (boolVal) => {
    // setDrawerSidebar(boolVal)

    if (boolVal) {
      setExpandedDrawer((prev) => ({ ...prev, status: boolVal }));
      setExpandedDrawer((prev) => ({ ...prev, fullTextActive: boolVal }));
    } else {
      setExpandedDrawer((prev) => ({
        ...prev,
        status: boolVal,
        fullTextActive: boolVal,
      }));
    }
  };

  const handleLogOut = () => {
    localStorage.clear();

    auth
      ?.signOut()
      .then(() => {
        // Sign-out successful.
        dispatch({ type: "user/logout" });
      })
      .catch((error) => {
        // An error happened.
        console.error("Error signing out:", error);
      });
    window.location.reload();
  };

  useEffect(() => {
    let activePath = null;
    let activeSubPath = null;
    SIDEBAR_DATA.forEach((el, index) => {
      if (location.pathname.trim().includes(el.name.trim())) {
        activePath = index;
        el.subcategories.forEach((element, index2) => {
          if (location.pathname.trim().includes(element.name.trim())) {
            activeSubPath = index2;
          }
        });
      }
    });
    if (activePath >= 0) setActiveIndex(activePath);
    else setActiveIndex(null);

    if (activeSubPath >= 0) {
      setActiveSubIndex(activeSubPath);
    } else setActiveSubIndex(null);
  }, [location.pathname]);

  return (
    <div
      onMouseEnter={() => handleSidebarExpansion(true)}
      onMouseLeave={() => handleSidebarExpansion(false)}
      className={`relative transition-all duration-[0.5s] ${
        expandedDrawer.status ? "w-[280px]" : "w-[5rem]"
      }  px-4 py-8 pt-[80px] bg-white border-r z-10 border-zinc-100 shadow-xl flex flex-col justify-between items-center`}
      style={{ overflow: "hidden", height: "100vh" }}
    >
      <div className="w-full flex flex-col justify-start items-start">
        {SIDEBAR_DATA.map((el, index) => {
          if (companyData ? checkPermission(companyData, el.route) : true)
            return el.subcategories.length > 0 ? (
              <React.Fragment key={index}>
                <div
                  onMouseEnter={() => {
                    handleDrawer(index);
                  }}
                  onMouseLeave={() => {
                    handleDrawer(null);
                  }}
                  id={index}
                  className={` min-h-[40px]  w-[240px] hover:text-violet-700 px-5 py-2.5 rounded-xl  hover:bg-gray-100 ${
                    activeIndex === index
                      ? "bg-violet-100 text-violet-700"
                      : null
                  }`}
                >
                  <div className="flex justify-start items-center gap-2.5">
                    <div className="  flex justify-center items-center">
                      <img src={el.icon} alt="" className="w-[22px] h-[22px]" />
                    </div>
                    {expandedDrawer.fullTextActive && (
                      <div
                        className={`text-base  font-normal font-['Manrope'] `}
                      >
                        {el.title}
                      </div>
                    )}
                  </div>
                </div>
                {drawer.index == index &&
                  el.subcategories.map((subEl, index2) => {
                    if (
                      companyData
                        ? checkPermission(companyData, subEl.route)
                        : true
                    )
                      return (
                        <NavLink
                          key={index2}
                          to={`${routes.user.path}/${el.name}/${subEl.name}`}
                          onMouseEnter={() => {
                            handleDrawer(index);
                          }}
                          onMouseLeave={() => {
                            handleDrawer(null);
                          }}
                          onClick={() => setActiveSubIndex(index2)}
                          id={index2}
                          className={`drawer-Content min-h-[40px] w-[240px] hover:text-violet-700 px-2 py-2.5  rounded-xl  hover:bg-gray-100 ${
                            activeSubIndex === index2 && activeIndex == index
                              ? "bg-violet-100 text-violet-900"
                              : null
                          }`}
                        >
                          <div className="flex pl-8 w-[240px] justify-start items-center gap-2.5">
                            <div
                              className={`text-base  font-normal font-['Manrope'] `}
                            >
                              {subEl.title}
                            </div>
                          </div>
                        </NavLink>
                      );
                  })}
              </React.Fragment>
            ) : (
              <NavLink
                key={index}
                to={`${routes.user.path}/${el.name}`}
                id={index}
                onClick={() => {
                  setDrawer({ index: null });
                  setActiveIndex(index);
                }}
                className={`min-h-[40px] w-[240px] hover:text-violet-700 px-5 py-2.5 rounded-xl  hover:bg-gray-100 ${
                  activeIndex === index ? "bg-violet-100 text-violet-700" : null
                }`}
              >
                <div className="flex justify-start items-center gap-2.5">
                  <div className=" flex justify-center items-center">
                    <img
                      src={el.icon}
                      alt={el.name}
                      className="w-[22px] h-[22px]"
                    />
                  </div>
                  {expandedDrawer.fullTextActive && (
                    <div className={`text-base  font-normal font-['Manrope'] `}>
                      {el.title}
                    </div>
                  )}
                </div>
              </NavLink>
            );
        })}
      </div>

      {expandedDrawer.fullTextActive ? (
        <div className="flex flex-col w-[15rem]">
          <CompanyCard companyData={companyData} />
          <div
            onClick={() => handleLogOut()}
            className="w-[100%] m-auto cursor-pointer h-11 px-5 py-2.5 flex flex-row self-stretch rounded-xl justify-center items-center gap-1 "
          >
            <img src={Logout_sign} className="w-[20px]" alt="" />
            <div className="text-neutral-800 text-xs font-bold font-['Manrope'] leading-none">
              Logout
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-2 w-full">
          <div>
            <img src={companyData?.logo} alt="" />
          </div>

          <div className="w-full h-[0px] border border-black/opacity-10"></div>

          <div
            onClick={handleLogOut}
            className="w-[100%] cursor-pointer h-11  py-2.5 flex flex-row self-stretch rounded-xl justify-center items-center gap-[2px]"
          >
            <img src={Logout_sign} className="w-[20px] m-auto" alt="" />
            <div className="text-neutral-800 text-xs font-bold font-['Manrope'] leading-none">
              Logout
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const CompanyCard = ({ onMouseLeave }) => {
  const navigate = useNavigate();
  const companyData = useSelector((state) => state.companySlice.companyData);

  return (
    <div
      // onMouseLeave={() => onMouseLeave()}
      className=" w-[100%] bg-indigo-500 bg-opacity-10 rounded-xl border border-indigo-500 border-opacity-10 flex flex-col justify-between"
    >
      <div className="p-4 flex items-center">
        <div className="w-6 h-6 bg-indigo-100 rounded-full mr-2">
          <img
            src={companyData?.logo}
            alt=""
            className="w-6 h-6 rounded-full"
          />
        </div>
        <p className="flex flex-col text-slate-800 text-sm font-semibold font-['Manrope']">
          {companyData ? companyData.Nm : "Company Name"}
        </p>
      </div>
      <div
        className="flex items-center p-2 bg-indigo-500 bg-opacity-30 rounded-b-xl cursor-pointer"
        onClick={() => navigate("/user/profileSetUp/")}
      >
        <div className="flex flex-row">
          <span className="">
            {companyData ? "Manage Your Company" : "+ Add Your Company"}
          </span>
        </div>
      </div>
    </div>
  );
};
