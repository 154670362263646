import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Account from "../../assets/images/TopNavBar/Account.png";
import QuestionMark from "../../assets/images/TopNavBar/QuestionMark.png";
// import Nextbill_logo from "../../assets/images/authentication/nextbill_logo.png"

import { auth, messaging } from "../../../firebase_config";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../../context/AuthContext";
import { baseUrl, endpoints } from "../../utils/axios";
import common_service from "../../api/common_service";
import nextbill_logo from "../../assets/images/Next_Bill_logo_crop.png";
import Notification from "../../assets/images/TopNavBar/Notification.png";
import {
  addCompanyData,
  addCompanyGstList,
  addLoding,
} from "../../redux/company/companySlice";
import { getToken } from "firebase/messaging";
import { initializePushNotifications } from "../../pushNotification";
import { ModalContext } from "../../context/ModalProvider";

function TopNavBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { showModal } = useContext(ModalContext);
  common_service.init(showModal);
  const [openMenu, setOpenMenu] = useState(false);
  const companyDetails = useSelector((state) => state.companySlice.companyData);
  const authData = useContext(AuthContext);
  const [userLogo, setUserLogo] = useState(null);

  // const requestPermission = async () => {
  //   const permission = await Notification.requestPermission();
  //   if (permission == "granted") {
  //     const token = await getToken(messaging, {
  //       vapidKey:
  //         "BNJS87L6GN21EmSgbmXxvLxreIO3u4veGaBAzE2MRk90v7nk2lNEavy27AgsWL5D-yz-CrNEBjnpoIa9Op4hzQs",
  //     });
  //     console.log(token);
  //     if (token) sendNotificationTokenToDB(token);
  //   } else if (permission == "denied") {
  //     alert("u denied the notification permission");
  //   }
  // };

  useEffect(() => {
    // if (authData?.authData?.accessToken) requestPermission();

    if (authData?.authData?.accessToken) {
      initializePushNotifications(authData);
    }
  }, [authData, location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openMenu && !event.target.closest(".top-navbar")) {
        setOpenMenu(false);
      }
    };

    const handleScroll = () => {
      if (openMenu) {
        setOpenMenu(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("scroll", handleScroll);
    };
  }, [openMenu]);

  const hadileLogOut = () => {
    localStorage.clear();

    auth
      ?.signOut()
      .then(() => {
        // Sign-out successful.
        dispatch({ type: "user/logout" });
      })
      .catch((error) => {
        // An error happened.
        console.error("Error signing out:", error);
      });
    window.location.reload();
  };

  const getUserData = async () => {
    let url = `${baseUrl}/${endpoints.userProfile.getUserDetailes}`;
    const res = await common_service.GET_REQUEST(
      url,
      authData.authData.accessToken
    );
    if (res.status >= 200 && res.status <= 300) {
      setUserLogo(res.data?.profile);
    } else console.log("userDeatiles failed");
  };

  const getCompanyProfileData = useCallback(async () => {
    dispatch(addLoding(true));
    let url = `${baseUrl}/${endpoints.company.company_detail}`;
    const response = await common_service.GET_REQUEST(
      url,
      authData?.authData?.accessToken
    );
    if (response?.status == 200) {
      if (response?.data?.company[0]) {
        // setCompanyData(response.data.company[0])
        dispatch(addCompanyData(response.data.company[0]));
      } else {
        // navigate("/user/dashboard")
        dispatch(addLoding(false));
      }
    } else {
      // alert("some error")
      dispatch([]);

      dispatch(addLoding(false));
    }
  }, [authData?.authData?.accessToken]);

  const fetchGstList = useCallback(
    async (authtoken) => {
      const url = `${baseUrl}/${endpoints.company.gst_list(
        companyDetails?.company_id
      )}`;
      const response = await common_service.GET_REQUEST(url, authtoken);
      if (response?.status == 200 || response?.status == 201) {
        // setGstTableData(response?.data?.companyGSTs)
        dispatch(addCompanyGstList(response?.data?.companyGSTs));
      } else {
        // setGstTableData([])
        // console.log("Something went wrong")
      }
      dispatch(addLoding(false));
    },
    [authData?.authData?.accessToken, companyDetails?.company_id]
  );

  // useEffect(() => {
  //   if (authData?.authData?.accessToken && !companyDetails) getUserData();
  // }, [authData?.authData?.accessToken, companyDetails]);

  useEffect(() => {
    if (authData?.authData?.accessToken) {
      getUserData();
    }
  }, [authData?.authData?.accessToken]);

  useEffect(() => {
    if (authData?.authData?.accessToken && !companyDetails) {
      let token = authData.authData?.accessToken;
      getCompanyProfileData(token);
    } else if (companyDetails) {
      fetchGstList(authData.authData?.accessToken);
      // navigate("/signin")
    }
  }, [authData?.authData?.accessToken, companyDetails]);

  return (
    <div className="">
      <div className="fixed top-0 left-0 right-0 z-10 flex min-h-[4rem] px-[32px] shadow bg-white border-b border-zinc-100  items-center justify-between">
        {/* left side part */}
        <div
          onClick={() => navigate("/user/dashboard")}
          className="text-violet-700 flex cursor-pointer text-[22px] font-extrabold font-['Manrope'] leading-[31px] tracking-widest"
        >
          <img src={nextbill_logo} alt="" className="w-[30px] h-[30px]" />

          {/* <div className="text-violet-700 text-[22px] font-extrabold font-['Manrope'] leading-[31px] tracking-widest">
            <img src={nextbill_logo} alt="" className="w-10 h-10" />
          </div> */}

          {/* <p className='pl-2'>Nextbill.</p> */}
        </div>
        {/* right side part */}
        <div className="top-navbar items-center gap-[1rem] hidden sm:flex">
          {/* <img className="w-8 h-8 cursor-pointer" src={Notification}></img> */}
          <img
            className="w-8 h-8 cursor-pointer"
            // onClick={() => setOpenMenu(!openMenu)}
            onClick={() => navigate("/user/userProfile")}
            src={userLogo || Account}
          ></img>
          <img className="w-8 h-8 cursor-pointer" src={QuestionMark}></img>
        </div>
      </div>
      {openMenu === true && (
        <div className="fixed top-16 right-16 bg-white w-[100px] border-solid z-50 max-h-[200px]">
          <ul>
            <li>
              <p
                onClick={() => hadileLogOut()}
                className="top-navbar cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              >
                Sign out
              </p>
            </li>
            <li onClick={() => navigate("/user/userProfile")}>
              <p className="block cursor-pointer w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">
                view profile
              </p>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default TopNavBar;
