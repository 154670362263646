export const baseUrl = import.meta.env.VITE_BACK_END_URL;
export const endpoints = {
  user: {
    login: "users/login",
    signin: "signin",
    signup: "users/signup",
    sendEmailOtp: "users/sendMailOtp",
    verifyEmailOtp: "users/verifyOtp",
    register: "users/register",
    notify: "notify",
    getNotificationPreferences: "getNotificationPreferences",
  },
  chat: {
    generalPurposeChat: "chat",
  },
  company: {
    company_register: "companies/register",
    createCompanyAndGst: "/company/createCompanyAndGST",
    company_detail: "companies/",
    gst_details: "company/gst/add",
    gst_list: (companyId) => `company/gst/all/${companyId}`,
    add_gst_details: "company/gst/add",
    edit_gst: (companyId, id) => `/company/gst/edit/${companyId}/${id}`,
    delete_gst: (companyId, id) => `/company/gst/delete/${companyId}/${id}`,
    // activate_gst: (companyID, gstId) =>
    // 	`/company/gst/edit/${companyID}/${gstId}`,
    activate_gst: (companyID, gstId) =>
      `company/gst/username/add/${companyID}/${gstId}`,
    company_edit: "company/edit",
    add_company_logo: (companyId) => `company/logo/${companyId}`,
    add_e_sign: (companyId) => `company/esign/${companyId}`,
    getCompanyTermsAndConditions: (companyId) =>
      `company/paymentTerm/${companyId}`,
    addCompanyTermsAndConditions: "company/paymentTerm/create",
    editCompanyTermsAndConditions: (companyId, paymentTermId) =>
      `company/paymentTerm/${companyId}/${paymentTermId}`,
    deleteCompanyTermsAndConditions: (companyId, paymentTermId) =>
      `company/paymentTerm/${companyId}/${paymentTermId}`,
    makeCompanyTermsAndConditionsDefault: (companyId, id) =>
      `company/paymentTerm/updatePaymentTermSelection/${companyId}/${id}`,
    addBank: "company/bank/add",
    getAllBankData: "company/bank/all", //need to add company id at end
    bankDataEdit: "company/bank/edit", // add /:companyId/:id
    makeBankDefault: `company/bank/select`,
    bankAccountDelete: "company/bank/delete", //add :companyId/:id
    addUpi: "company/bankupi/add",
    getAllUpiData: "company/bankupi/all", // add company id at end
    upiEdit: "company/bankupi/edit", //add comapny id,upi id at end
    upiDelete: "company/bankupi/delete", //add comapnay id,upi id at aend
    makeUPIDefault: `company/bankupi/select`,
    add_dispatch: "company/dispatch/add",
    edit_dispath: (companyId, id) => `company/dispatch/edit/${companyId}/${id}`,
    delete_dispatch: (companyId, id) =>
      `company/dispatch/delete/${companyId}/${id}`,
    getAllDispatchList: (companyId) => `company/dispatch/all/${companyId}`,
    add_transporter: "company/transporter/add",
    edit_transporter: (companyId, id) =>
      `company/transporter/edit/${companyId}/${id}`,
    delete_transporter: (companyId, id) =>
      `company/transporter/delete/${companyId}/${id}`,
    getAllTransporterList: (companyId) =>
      `company/transporter/all/${companyId}`,
    comapnyGstSendMail: (companyId, companyGstId) =>
      `/company/gst/mail/${companyId}/${companyGstId}`,
  },

  items: {
    addItem: "company/item/add",
    getAllItems: "company/item/paginated", //add comapny id at end
    deleteItem: "company/item/delete", //add comapny id,itema id at end
    editItem: "company/item/edit", //add company id,item id at end.
    getHsn: "hsn",
    getItemById: "company/item/one", // add company id and item id at end
  },
  customer: {
    checkCustomer: "company/customer/check",
    addCustomer: `company/customer&gst/add`,
    addCustomerGst: "company/customerGst/add",
    addCustomerWithOutGst: "company/customer/add",
    addDelivery: "company/deliveryAddress/add",
    getAllcustomers: "company/customer/all", //add comapny id at end
    getCustomerById: "company/customer/single", //add   company id,customer id at end
    getCustomerGstById: "company/customerGst/one", //add companyid , customer id at end
    gst_list: (companyId, customerId) =>
      `company/customerGst/all/${companyId}/${customerId}`,
    deliveryAddressList: (companyId, customerId) =>
      `company/deliveryAddress/all/${companyId}/${customerId}`,
    editCustomerData: "company/customer/edit", //add company id and customer id at end
    editCustomerGst: "company/customerGst/edit", //add company id and customer gst id at end
    editCustomerDeliveryById: "company/deliveryAddress/edit", //add company id and delivery id at end
    deleteCustomerById: "company/customer/delete", //add company id and customer id at end
    deleteCustomerGstById: "company/customerGst/delete", //add copmany id and delete cuastomer gst id
    deleteCustomerDeliveryAddress: "company/deliveryAddress/delete", // add company id nd delivary address
    customerInvoiceById: "company/bothInvoices", //add comapny id and customer id at end
  },
  invoice: {
    getAllInvoices: "company/taxInvoice/all/company", //add company id at end
    getGeneratedInvoice: "company/taxInvoice/all/company", //add company id and page and pageSize,search  at end
    deletInvoiceBhyId: "company/taxInvoice/delete", //add company id,tax id at end
    getGeneratedInvoiceById: "company/taxInvoice/single", //add company id and taxinvoice id at end
    editInvoiceById: "company/taxInvoice/edit", //add comapny id and taxinvoice id at end
    taxInvoiceDataWithTimeRange: `company/taxInvoice/dashBoard`,
    updateTaxInvoice: (companyId, taxInvoiceId) =>
      `company/taxInvoice/payment/${companyId}/${taxInvoiceId}`,
  },
  taxInvoice: {
    addTaxInvoice: "company/taxInvoice/add",
    getInvoiceByPaymenyStatus: (companyId, incoiceType) =>
      `taxinvoices/${companyId}/payment-status?status=${incoiceType}`,
    getTaxIvoiceByID: (companyID, invoiceID) =>
      `company/taxInvoice/single/${companyID}/${invoiceID}`,
    nextInvoiceNumber: (companyID, docType) =>
      `company/taxInvoice/nextInvoicePrefix/${companyID}/${docType}`,
    downloadTaxInvoicePDF: (companyId, taxInvoiceId) =>
      `company/taxInvoice/pdf/${companyId}/${taxInvoiceId}`,
    downloadEWayBillPDF: (companyId, taxInvoiceId) =>
      `company/ewayBill/pdf/${companyId}/${taxInvoiceId}`,
    sendmail: (companyId, taxInvoiceId) =>
      `company/taxInvoice/mail/${companyId}/${taxInvoiceId}`,
    generateReport: (companyId) => `generate-Tax_invoice/${companyId}`,
    cancelTaxInvoice: (companyId, taxInvoiceId) =>
      `company/cancelTaxInvoice/${companyId}/${taxInvoiceId}`,
    editTaxInvoice: (companyId, taxInvoiceId) =>
      `company/taxInvoice/edit/${companyId}/${taxInvoiceId}`,
  },
  userProfile: {
    uploadProfile: "company/logo", //add company id at end
    editUserProfile: "users/profile/update",
    getUserDetailes: "users/single/profile",
    forgetPassword: `users/forgotPassword`,
    emailChange: `/users/changeEmail`,
    sendOTPToPhone: `/users/sendOTP`,
    sendOTPToMail: `/users/sendMailOtp`,
    verifyEmailOtpAndUpdatePhoneNumber: `/users/changeEmail`,
    uploadUserPhoto: `users/uploadProfile`,
    changePhoneNumber: `users/changePhone`,
  },
  estimate: {
    sendEstimateMail: "company/estimate/mail", //add comapny id and estimate id at end
    downloadPdf: "company/estimate/pdf", //add comapnyid and estimate id at end
    addEstimate: "company/estimate/add",
    getAllEstimate: "company/estimate/all/company", //add company id at end
    deleteEstimateById: "company/estimate/delete", //add company id and estimate id at end
    getEstimateById: "company/estimate/single", //add companyid and etimateid at end.
    editEstimateById: "company/estimate/edit", //add company id and estimate id at end
    deleteEstimateInEditMode: "company/estimate/item/delete", //add company id and estimate item id at end
    nextEstimateNumber: `company/next-estimate-number`,
  },
  e_way_bill: {
    //mailes
    sendMailWithEwayBillTaxinvoice: (company_id, e_waybill_internal_id) =>
      `company/ewayBill/mail/${company_id}/${e_waybill_internal_id}?details=${true}`,
    sendMailWithEwayBillEinvoice: (
      company_id,
      ewaybill_external_without_irn_id
    ) =>
      `EwaybillWithEinvoice/mail/${company_id}/${ewaybill_external_without_irn_id}?details=${false}`,
    sendMaileWithOutIrn: (company_id, ewaybill_external_without_irn_id) =>
      `ewayBill/external/mail/${company_id}/${ewaybill_external_without_irn_id}?details=${false}`, //add comapny id and ewaybill id at end
    sendMaileWithIrn: (company_id, ewaybill_external_with_irn_id) =>
      `ewayBill/mail/${company_id}/${ewaybill_external_with_irn_id}`, //add comapny id and ewaybill id at end
    // pdfs
    pdfEwayBillWithIrn: "ewayBill/pdf", // add eway bill id at end
    pdfEwayBillWithOutIrn: "ewayBill/external/pdf", // add eway bill id at end
    // delet
    deleteEwayBillWithTaxInvoice: "company/ewayBill/delete",
    deleteEwayBillWithEinvoice: "EwaybillWithEinvoice/delete",
    deleteEwayBillWithIrn: "ewayBill/external/delete", //add company id and e-way bill id at end
    deleteEwayBillWithOutIrn: "ewayBill/externalWithoutIrn/delete", //add company id and e-way bill id at end
    addEwayBill_FromEinvoice: "EwaybillWithEinvoice/create",
    addEWayBillWithIrn: "ewayBill/create",
    getAllEWayBillWithIrn: "ewayBill/getEwayBillsByUser",
    getAllEWayBillWithOutIrn: "ewayBill/BothexternalEwayBillsByUser",
    getAllEwayBillWithTaxInvoice: "company/getBothEwayBillsCompany", // add comapny id at end
    addEWayBillWithOutIrn: "ewaybill/external/create",
    updateTransportWithOutIrn: "ewayBill/external/updateTransporter", // add transport id at end
    addEWayBill: "company/ewayBill/add",
    updateVehicleNumber: "ewayBill/external/updateVehNumber", // add vechile number at end
    cancelEwayBillWithoutIrn: "ewayBill/external/cancelEwayBill", // add eay bill id at end
    cancelEwayBillWithIrn: "ewayBill/cancelEwayBill", // add eay bill id at end
    cancelEwayBillWithTaxInvoice: "company/ewayBill/cancelEwayBill", //add comapny id and eway bill id at end
    cancelEwayBillWithEinvoice: "EwaybillWithEinvoice/cancelEwayBill", //add comapny id and eway bill id at end
    updateVehicleNumberWithIrn: "ewayBill/updateVehNumber", //add eay bill id aat end
    updateVehicleNumberWithTaxInvoice: "/company/ewayBill/updateVehNumber", //add comaapny id and eway bill id at end
    updateVehicleNumberWithEinvoice: "EwaybillWithEinvoice/updateVehNumber", //add comaapny id and eway bill id at end
    updateTransportWithIrn: "ewayBill/updateTransporter", //add eay bill id aat end
    updateTransportWithTaxInvoice: "company/ewayBill/updateTransporter", // add comapny id and eway bill id at end
    updateTransportWithEinvoice: "EwaybillWithEinvoice/updateTransporter", // add comapny id and eway bill id at end
    getEwayBillWithIrnById: "ewayBill/getEwayBillById", //add eway bill id aat end
    getEwayBillWithOutIrnById: "ewayBill/external/getEwayBillById", //add eway bill id aat end
    getEwayBillWithTaxInvoiceById: "company/ewayBill/single", //add company id && eway bill id aat end
    downloadPDF: (ewayBillId) => `ewayBill/pdf/${ewayBillId}`,
    getEwayBillWithEinvoiceById: "EwaybillWithEinvoice/getEwayBillById",
  },

  e_invoice: {
    nextInvoiceNumber: (companyID, docType) =>
      `company/einvoice/next/${companyID}/${docType}`,
    getAllInvoices: (companyId) => `company/einvoice/all/company/${companyId}`,
    getInvoiceByID: (companyId, eInvoiceId) =>
      `company/einvoice/single/${companyId}/${eInvoiceId}`,
    addEInvoice: `company/einvoice/add`,
    updateEInvoice: (companyId, eInvoiceId) =>
      `company/einvoice/edit/${companyId}/${eInvoiceId}`,
    taxInvoiceDataWithTimeRange: `company/eInvoice/dashBoard`,
    getInvoiceByPaymenyStatus: (companyId, incoiceType) =>
      `einvoices/${companyId}/payment-status?status=${incoiceType}`,
    downloadEInvoicePDF: (companyId, eInvoiceId) =>
      `company/einvoice/pdf/${companyId}/${eInvoiceId}`,
    downloadEwayBillPDF: (companyId, eWayBillId) =>
      `EwaybillWithEinvoice/pdf/${companyId}/${eWayBillId}`,
    cancelEinvoice: "company/einvoice/cancel", //add comapny id and eInvoiceid id at end
    sendmail: (companyId, taxInvoiceId) =>
      `company/einvoice/mail/${companyId}/${taxInvoiceId}`,
    generateReport: (companyId) => `generate-Einvoice/${companyId}`,
  },

  gstDetails: {
    getDetailsFromGST: (gstNumber) => `search?gstin=${gstNumber}`,
    cancelEinvoice: "company/einvoice/cancel", //add comapny id and eInvoiceid id at end
  },
  pincode: {
    getDetailsFromPin: (pincode) =>
      `https://api.postalpincode.in/pincode/${pincode}`,
  },

  dashboard: {
    getAllStatsUrl: (companyId) => `company/getAllStats/${companyId}`,
    getAllMonthlyStatsUrl: (companyId, year) =>
      `company/getAllStatsByMonth/${companyId}?year=${year}`,
    getYearlyPendingAmtUrl: (companyId, year) =>
      `company/getPendingInvoice/${companyId}?year=${year}`,
  },
  Recurring_Invoice: {
    sendRecurringMail: (companyId, recurring_invoice_id) =>
      `company/recurring/mail/${companyId}/${recurring_invoice_id}`, //add comapy id and mail at end
    getAllEinvoicesWithoutRecurring: "company/recurring/eInvoice", //add company id at end
    getAllTaxinvoicesWithoutRecurring: "company/recurring/taxInvoice", //add company id at end
    getAllRecurring_Invoice: "company/recurring/all", // add company id at end
    createRecurring_Invoice: "company/recurring",
    updateRecurring: "company/recurring", // add comapnyid/recusring_id
    generatedHistory: "company/generated/history", //add comapny id at end
    getRecurring_InvoiceById: "company/recurring/single", // ADD COMAPYID AND RECUR ID
    getAllRecurring_InvoicesById: "company/generated/history/recurring", //add company id and recuid at end
  },
  subscription: {
    checkSubscriptionForTaxInvoice: "canCreateTaxInvoice",
    checkSubscriptionForEInvoice: "canCreateEInvoice",
    checkSubscriptionForRecurringTaxInvoice: "canRecCreateTaxInvoice",
    checkSubscriptionForRecurringEInvoice: "canRecCreateEInvoice",
    checkSubscriptionForEwayBill: "canCreateEwayBILL",

    getUserSubsCriptionWhileLogin: `getUserSubscriptions`,
    getAllUserSubscription: `getAllSubscriptionsForUser`,
    createSubscription: `createSubscription`,
    verifyPayment: `verifyPayment`,
    getAllSubscriptionHistory: `getAllSubscriptionsForUser`,
    cancelSubscription: `cancelSubscription`,
    upgradeSubscription: `updateSubscription`,
    getSubscriptionPDF: (companyId, subscriptionId) =>
      `company/subscription/pdf/${companyId}/${subscriptionId}`,
    refundPolicy: "createSubscriptionRefund", //add subscriptionId id at end
    getSubscriptionRefundStatus: "getSubscriptionRefundStatus",

    getAllSubscriptionPaymentList: `company/subscription/payments`,
  },
  plans: {
    getAllplans: `getAllPlans`,
  },
  topup: {
    getAllTopupPlans: `getAllAddonPlans`,
    buyTopup: `createAddon`,
  },

  selfInvoice: {
    sendMail: (company_id, self_invoice_id) =>
      `/company/selfInvoice/mail/${company_id}/${self_invoice_id}`, //add company id and self invoice id at end
    nextSelfInvoiceNumber: "company/selfInvoice/nextPrefix", ///add company id at the end
    createSelfInvoice: "company/selfInvoice",
    getSelfInvoiceById: "company/selfInvoice", //add companyid and selfInvoiceid at the end.
    getAllSelfInvoice: (companyId) => `company/selfInvoice/all/${companyId}`, //add company id at end
    deleteSelfInvoiceById: "company/selfInvoice", //add company id and selfinvoice id at end
  },
  paymentReminder: {
    paymentReminderToSingleUser: (companyId) => `company/reminder/${companyId}`,
    paymentTaxInvoiceReminderToAll: (companyId) =>
      `company/taxInvoice/reminder/${companyId}`,
    paymentEInvoiceReminderToAll: (companyId) =>
      `company/einvoice/reminder/${companyId}`,
  },

  notification: {
    sendTokenToDb: "notify",
  },

  gstr1: {
    requestOTPtoLogin: "requestOtp",
    otpVerifyAndAuthToken: "requestAuthToken",
    getAllBankDataInvoiceByDateRange: (companyId) =>
      `company/taxInvoice/getTaxInvoicesByDateRange/${companyId}`,
    getAllBankDataInvoiceInJSON: (companyId) =>
      `company/taxInvoice/getTaxInvoicesForGstR1/${companyId}`,
    generateReportExcel: (companyId) =>
      `company/taxInvoice/generateTaxInvoiceExcel/${companyId}`,
    getGSTR1HistoryForB2B: (companyId) => `company/getB2BInvoices/${companyId}`,
    getGSTR1HistoryForB2CL: (companyId) =>
      `company/getB2CLInvoices/${companyId}`,
    getGSTR1HistoryForB2CS: (companyId) =>
      `company/getB2CSInvoices/${companyId}`,
    getGSTR1HistoryForCDNR: (companyId) =>
      `company/getCDNRInvoices/${companyId}`,
    getGSTR1HistoryForCDNUR: (companyId) =>
      `company/getCDNURInvoices/${companyId}`,
    getGSTR1HistoryForEXP: (companyId) =>
      `company/getExportInvoices/${companyId}`,
    getGSTR1HistoryForHSN: (companyId) => `company/getHSNSummary/${companyId}`,
    getGSTR1HistoryForADV: (companyId) =>
      `company/getAdavanceInvoices/${companyId}`,
    getGSTR1HistoryForADVA: (companyId) =>
      `company/getRevertAdavanceInvoices/${companyId}`,
    saveGSTR1AllInvoices: (companyId) => `company/SaveGstR1/${companyId}`,
    proceedGSTR1AllInvoices: (companyId) =>
      `company/newproceedfile/${companyId}`,
    getGSTR1FillingSummary: (companyId) =>
      `company/getReturnSummary/${companyId}`,
    sendEVCOtpToPhone: (companyId) => `company/initiateOtpForEVC/${companyId}`,
    fileGSTR1: (companyId) => `/company/fileGstr1/${companyId}`,
    getGSTR1StatusDetails: (companyId) =>
      `company/getGstR1Details/${companyId}`,
  },

  ammendment: {
    getAllAmendmentInvoices: (companyId) =>
      `company/getAmendInvoices/${companyId}`,
    SaveGstAmmendR1: (companyId) => `company/SaveGstAmmendR1/${companyId}`,
    getSingleInvoice: (companyId) => `company/getInvoice/${companyId}`,
    createAmendInvoice: (companyId) =>
      `company/createAmendInvoice/${companyId}`,
  },

  advance: {
    getAdvanceList: (companyId) => `company/getAllAdvanceInvoices/${companyId}`,
    createAdvance: (companyId) => `company/createAdvance/${companyId}`,
    getAdvanceByID: (companyId, advancesId) =>
      `company/getInvoiceById/${companyId}/${advancesId}`,
    updateAdvanceByID: (companyId, advancesId) =>
      `company/updateAdvances/${companyId}/${advancesId}`,
    deleteAdvanceByID: (companyId, advancesId) =>
      `company/deleteInvoiceById/${companyId}/${advancesId}`,
    cancelAdvanceByID: (companyId, advancesId) =>
      `company/cancelInvoiceById/${companyId}/${advancesId}`,
    adjustAdvanceWithInvoice: (companyId, advancesId) =>
      `company/attachInvoice/${companyId}/${advancesId}`,
  },

  // pincode: {
  // 	getDetailsFromPin: (pincode) =>
  // 		`https://api.postalpincode.in/pincode/${pincode}`,
  // },
};
