import { useEffect } from "react";

const useHMRRefresh = () => {
  useEffect(() => {
    if (import.meta.hot) {
      import.meta.hot.on("vite:beforeFullReload", () => {
        alert("The application has been updated. Please refresh the page.");
      });
    }
  }, []);
};

export default useHMRRefresh;
