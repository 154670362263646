import axios from "axios";

// Check if the token is expired
const isTokenExpired = (token) => {
  if (!token) return true; // Token is invalid if not provided
  const payload = JSON.parse(atob(token.split(".")[1])); // Decode JWT
  const expiration = payload.exp * 1000; // Convert to milliseconds

  return Date.now() >= expiration; // Check if current time is greater than expiration
};

const cleanPayload = (payload) => {
  return Object.fromEntries(
    Object.entries(payload).map(([key, value]) => {
      if (typeof value === "string") {
        // Trim leading/trailing spaces and replace multiple spaces within
        value = value.trim().replace(/\s+/g, " ");
      }
      return [key, value]; // Return cleaned key-value pair
    })
  );
};

// Create an API service object
const common_service = {
  showModal: null, // Placeholder for modal function

  // Set modal function from context
  init: (showModalFunc) => {
    common_service.showModal = showModalFunc;
  },

  GET_REQUEST: async (url, token, params) => {
    if (token && isTokenExpired(token)) {
      common_service.showModal("Token has expired. Please reload to continue.");
    }
    return await axios
      .get(url, {
        headers: { Authorization: `${token}` },
        params: params || null,
        withCredentials: true,
      })
      .then(
        (response) => response,
        (error) => {
          return (
            error?.response?.data?.error?.error?.message ||
            error?.response?.data?.message ||
            error?.response?.data?.error ||
            error?.response?.data?.error[0] ||
            "Server Error"
          );
        }
      );
  },

  POST_REQUEST: async (url, token, data) => {
    if (token && isTokenExpired(token)) {
      common_service.showModal("Token has expired. Please reload to continue.");
      return null; // Or handle redirection to login
    }
    if (data) data = cleanPayload(data);
    return await axios
      .post(url, data, {
        headers: { Authorization: `${token}` },
        withCredentials: true,
      })
      .then(
        (response) => {
          return response;
        },
        (error) => {
          // console.error(error?.response?.data?.errors["Em.*"]);
          return (
            error?.response?.data?.message ||
            error?.response?.data?.error ||
            error?.response?.data?.error[0] ||
            error?.response?.data?.errors ||
            "Server Error"
          );
        }
      );
  },

  PUT_REQUEST: async (url, token, data) => {
    if (token && isTokenExpired(token)) {
      common_service.showModal("Token has expired. Please reload to continue.");
      return null; // Or handle redirection to login
    }
    if (data) data = cleanPayload(data);

    return await axios
      .put(url, data, {
        headers: { Authorization: `${token}` },
        withCredentials: true,
      })
      .then(
        (response) => response,
        (error) => {
          console.error(error);
          return (
            error?.response?.data?.message ||
            error?.response?.data?.error ||
            error?.response?.data?.error[0] ||
            "Server Error"
          );
        }
      );
  },

  DELETE_REQUEST: async (url, token) => {
    if (token && isTokenExpired(token)) {
      common_service.showModal("Token has expired. Please reload to continue.");
      return null; // Or handle redirection to login
    }
    return await axios
      .delete(url, { headers: { Authorization: `${token}` } })
      .then(
        (response) => response,
        (error) => {
          console.error(error);
          return (
            error?.response?.data?.message ||
            error?.response?.data?.error ||
            error?.response?.data?.error[0] ||
            error?.response?.data?.Em ||
            "Server Error"
          );
        }
      );
  },

  PATCH_REQUEST: async (url, token, data) => {
    if (token && isTokenExpired(token)) {
      common_service.showModal("Token has expired. Please reload to continue.");
      return null; // Or handle redirection to login
    }
    if (data) data = cleanPayload(data);

    return await axios
      .patch(url, data, {
        headers: { Authorization: `${token}` },
        withCredentials: true,
      })
      .then(
        (response) => response,
        (error) => {
          console.error(error);
          return (
            error?.response?.data?.message ||
            error?.response?.data?.error ||
            error?.response?.data?.error[0] ||
            "Server Error"
          );
        }
      );
  },
};

export default common_service;
